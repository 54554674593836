import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout className="flex flex-col items-start px-6 py-5 md:px-10 md:py-8 xl:px-16 xl:py-12">
    <SEO title="404: Not found" />
    <h1 className="mb-10">
      <Link to="/">Marie Grob</Link>
    </h1>
    <h2>error 404 – not found</h2>

    <p className="mb-10">You just hit a route that doesn't exist.</p>
    <Link to="/">back to home page</Link>
  </Layout>
)

export default NotFoundPage
